import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Icon from '../components/Icon';

export default () => (
  <Layout pageTitle="Page not found" pathname="/">
    <div className="flex-justify-center small-page-container">
        <div className="flex-justify-center success-icon">
            <Icon width={100} iconName="frown" />
        </div>
        <h4 className="margin-top-2">PAGE NOT FOUND</h4>
        <p>Sorry, but the page you've requested has been moved or no longer exists.</p>
        <div className="margin-top-2">
            <Link to="/" className="btn primary-btn rounded-btn full-width-mobile">BACK TO HOME PAGE</Link>
        </div>
    </div>
  </Layout>
)
